import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import { Fragment } from 'react';

const ResourceProperties = ({ detail }) => {
  return (
    <Fragment>
      <Box component={Paper} sx={{ my: '20px', p: '20px', boxShadow: '0' }}>
        <Typography variant="h6">Resource Properties</Typography>
        <Divider sx={{ mt: '10px', mb: '15px' }} />
        <TableContainer component={Paper} sx={{ boxShadow: '0' }}>
          <Table aria-label="proptable">
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Scope</TableCell>
                <TableCell>
                  {detail.scope === 'global' ? (
                    <Box
                      sx={{
                        backgroundColor: '#ff1744',
                        borderRadius: 1,
                        boxShadow: '0',
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontFamily="Fira Code Light"
                        fontWeight="300"
                        sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                      >
                        {detail.scope}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ backgroundColor: '#00c853', borderRadius: 1, boxShadow: '0' }}>
                      <Typography
                        variant="body2"
                        fontFamily="Fira Code Light"
                        fontWeight="300"
                        sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                      >
                        {detail.scope}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Valid Characters</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: '#424242',
                      borderRadius: 1,
                      boxShadow: '0',
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily="Fira Code Light"
                      fontSize={12}
                      fontWeight="300"
                      sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                    >
                      {detail.validText}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Min Length</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: '#424242',
                      borderRadius: 1,
                      boxShadow: '0',
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily="Fira Code Light"
                      fontSize={12}
                      fontWeight="300"
                      sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                    >
                      {detail.lengthMin}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Max Length</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: '#424242',
                      borderRadius: 1,
                      boxShadow: '0',
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily="Fira Code Light"
                      fontSize={12}
                      fontWeight="300"
                      sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                    >
                      {detail.lengthMax}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Regex</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: '#424242',
                      borderRadius: 1,
                      boxShadow: '0',
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily="Fira Code Light"
                      fontSize={12}
                      fontWeight="300"
                      sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                    >
                      {detail.regx}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shortname</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      backgroundColor: '#424242',
                      borderRadius: 1,
                      boxShadow: '0',
                    }}
                  >
                    <Typography
                      variant="body2"
                      fontFamily="Fira Code Light"
                      fontSize={12}
                      fontWeight="300"
                      sx={{ p: '5px', display: 'flex', justifyContent: 'center' }}
                    >
                      {detail.ShortName}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default ResourceProperties;
