import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import BugReportIcon from '@mui/icons-material/BugReport';

const Navbar = () => {
  return (
    <div className="navbar">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: '#02a6f2' }}>
          <Toolbar>
            <IconButton size="xlarge" edge="start" color="inherit" aria-label="menu">
              <ReplayCircleFilledIcon sx={{ fontSize: 35, mr: 2 }} />
            </IconButton>
            <Typography variant="h6" component="div">
              Azure Naming Generator
            </Typography>
            <Box
              sx={{
                backgroundColor: 'warning.dark',
                borderRadius: 1,
                boxShadow: '0',
                mx: '19px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                px: '10px',
                py: '5px',
              }}
            >
              <BugReportIcon />
              <Typography variant="h6">&nbsp;beta version</Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default Navbar;
