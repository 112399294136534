import IconButton from '@mui/material/IconButton';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import copy from 'clipboard-copy';

const CopyToClipboardButton = ({ text }) => {
  const handleClick = () => {
    copy(text);
  };

  return (
    <IconButton onClick={handleClick}>
      <ContentPasteIcon color="info" />
    </IconButton>
  );
};

export default CopyToClipboardButton;
