export const regions = [
  {
    short: 'weur',
    name: 'West Europe',
    location: 'Netherlands',
  },
  {
    short: 'neur',
    name: 'North Europe',
    location: 'Ireland',
  },
  {
    short: 'sea',
    name: 'Southeast Asia',
    location: 'Singapore',
  },
  {
    short: 'aue',
    name: 'Australia East',
    location: 'New South Wales',
  },
  {
    short: 'brs',
    name: 'Brazil South',
    location: 'São Paulo State',
  },
  {
    short: 'usc',
    name: 'Central US',
    location: 'Iowa',
  },
];
