export const envs = [
  {
    name: 'prod',
  },
  {
    name: 'dev',
  },
  {
    name: 'test',
  },
  {
    name: 'stage',
  },
  {
    name: 'sandbox',
  },
  {
    name: 'uat',
  },
];
