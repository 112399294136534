import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import LanguageIcon from '@mui/icons-material/Language';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import WorkIcon from '@mui/icons-material/Work';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useState, useEffect } from 'react';
import { axiosGet, axiosPost } from '../helpers/fetch';
import { regions } from '../shared/regions.mjs';
import { envs } from '../shared/envs.mjs';
import NamingResult from './NamingResult';
import ResourceProperties from './ResourceProperties';

const Generator = () => {
  const [error, setError] = useState(false);
  const [namespace, setNamespace] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState('');
  const [resourceProvider, setResourceProvider] = useState([]);
  const [selectedResourceProvider, setSelectedResourceProvider] = useState('');
  const [resourceDetail, setResourceDetail] = useState([]);
  const [region, setRegion] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [workload, setWorkload] = useState('');
  const [env, setEnv] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState('');
  const [openResult, setOpenResult] = useState(false);

  const handleSelectNamespace = (e) => {
    setSelectedNamespace(e.target.value);
    setOpenResult(false);
  };

  const handleSelectResourceProvider = (e) => {
    setSelectedResourceProvider(e.target.value);
    setOpenResult(false);
  };

  const handleSelectRegion = (e) => {
    setSelectedRegion(e.target.value);
    setOpenResult(false);
  };
  const handleSelectEnv = (e) => {
    setSelectedEnv(e.target.value);
    setOpenResult(false);
  };

  const handleWorkload = (e) => {
    setWorkload(e.target.value);
    setOpenResult(false);
  };

  const handleOnClick = () => {
    if (selectedResourceProvider && selectedEnv && selectedRegion && workload) {
      setOpenResult(true);
    }
  };
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  useEffect(() => {
    let mounted = true;

    axiosGet('/api/namespaces')
      .then((items) => {
        if (mounted) {
          setNamespace(items.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });

    return () => (mounted = false);
  }, [selectedNamespace]);

  useEffect(() => {
    let mounted = true;
    if (selectedNamespace !== '') {
      axiosPost(`/api/resourceproviders`, { namespace: selectedNamespace })
        .then((items) => {
          if (mounted) {
            setResourceProvider(items.data.resourcetypes);
            setSelectedResourceProvider('');
          }
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    }
    return () => (mounted = false);
  }, [selectedNamespace]);

  useEffect(() => {
    let mounted = true;
    axiosPost(`/api/resourcedetails`, {
      resourcetype: selectedResourceProvider,
    })
      .then((items) => {
        if (mounted) {
          setResourceDetail(items.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });

    return () => (mounted = false);
  }, [selectedResourceProvider]);

  useEffect(() => {
    let mounted = true;

    try {
      if (mounted) {
        setRegion(regions);
        setEnv(envs);
      }
    } catch (err) {
      console.log(err);
      setError(true);
    }

    return () => (mounted = false);
  }, []);

  return (
    <div className="generator">
      <Container size="large">
        <Grid
          container
          spacing={3}
          sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
        >
          <Grid xs={7}>
            <Box component={Paper} sx={{ my: '20px', p: '20px', boxShadow: '0' }}>
              {namespace && (
                <Stepper orientation="vertical">
                  <Step expanded={true} active={true}>
                    <StepLabel icon={<CloudCircleIcon />}>
                      <Typography>Select Resource Provider Namespace</Typography>
                    </StepLabel>
                    <StepContent>
                      <TextField
                        id="tfnamespace"
                        value={selectedNamespace}
                        onChange={handleSelectNamespace}
                        select
                        sx={{ width: '100%', mt: '15px' }}
                      >
                        {namespace.map((e) => (
                          <MenuItem key={e} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StepContent>
                  </Step>
                  <Step expanded={true} active={true}>
                    <StepLabel icon={<CloudSyncIcon />}>
                      <Typography>Select Resource Provider</Typography>
                    </StepLabel>
                    <StepContent>
                      <TextField
                        id="tfresourceprovider"
                        value={selectedResourceProvider}
                        onChange={handleSelectResourceProvider}
                        select
                        sx={{ width: '100%', mt: '15px' }}
                      >
                        {resourceProvider &&
                          resourceProvider.map((e) => (
                            <MenuItem key={e} value={e}>
                              {e}
                            </MenuItem>
                          ))}
                      </TextField>
                    </StepContent>
                  </Step>
                  <Step expanded={true} active={true}>
                    <StepLabel icon={<LanguageIcon />}>
                      <Typography>Select Region</Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        <Link
                          href="https://azure.microsoft.com/en-us/explore/global-infrastructure/geographies/#geographies"
                          underline="none"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Azure Region&nbsp;
                          <OpenInNewIcon fontSize="inherit" color="info" />
                        </Link>
                      </Typography>
                      <br />
                      <TextField
                        id="tfresourceprovider"
                        value={selectedRegion}
                        onChange={handleSelectRegion}
                        select
                        sx={{ width: '100%', mt: '15px' }}
                      >
                        {region.map((e) => (
                          <MenuItem key={e.short} value={e.short}>
                            {`${e.name} (${e.location})`}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StepContent>
                  </Step>
                  <Step expanded={true} active={true}>
                    <StepLabel icon={<CallSplitIcon />}>
                      <Typography>Select Environment</Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        Please select the environment the resource will run in
                      </Typography>
                      <br />
                      <TextField
                        id="tfresourceprovider"
                        value={selectedEnv}
                        onChange={handleSelectEnv}
                        select
                        sx={{ width: '100%', mt: '15px' }}
                      >
                        {env.map((e) => (
                          <MenuItem key={e.name} value={e.name}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </StepContent>
                  </Step>
                  <Step expanded={true} active={true}>
                    <StepLabel icon={<WorkIcon />}>
                      <Typography>Workloadname</Typography>
                    </StepLabel>
                    <StepContent>
                      <Typography variant="caption">
                        Workloadname should ideally describe the workload the resource performs.
                        some more options: Department name, Unit name, Resource function,...
                      </Typography>
                      <br />
                      <TextField
                        id="tfresourceprovider"
                        value={workload}
                        required
                        onChange={handleWorkload}
                        sx={{ width: '100%', my: '15px' }}
                      ></TextField>
                    </StepContent>
                  </Step>
                </Stepper>
              )}
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ mt: '15px' }}
              >
                <Button variant="contained" onClick={handleOnClick}>
                  Generate
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5}>
            <ResourceProperties detail={resourceDetail} />
            {resourceDetail && openResult && (
              <NamingResult
                shortName={resourceDetail.ShortName}
                region={selectedRegion}
                env={selectedEnv}
                workload={workload}
                detail={resourceDetail}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" variant="filled">
          Error retrieving data
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Generator;
