import axiosRetry from 'axios-retry';
import axios from 'axios';

const fetchcfg = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000,
};

const client = axios.create(fetchcfg);

export const axiosGet = (url) => {
  axiosRetry(client, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
  return client.get(url, fetchcfg);
};

export const axiosPost = (url, payload) => {
  axiosRetry(client, { retries: 5, retryDelay: axiosRetry.exponentialDelay });
  return client.post(url, payload, fetchcfg);
};
