/* eslint-disable no-extend-native */
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import { useEffect, useState } from 'react';
import CopyToClipboardButton from './CopyToClipboardButton';

const NamingResult = ({ region, env, workload, detail }) => {
  const [isGlobal, setIsGlobal] = useState(false);
  const [resourceName, setResourceName] = useState('');
  const [noShortName, setNoShortName] = useState(false);

  const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

  useEffect(() => {
    if (detail.ShortName === '') {
      setNoShortName(true);
    }
  }, [detail]);

  useEffect(() => {
    const re = new RegExp(detail.regx);

    const shorten = (name, dtl) => {
      if (name.length > dtl.lengthMax) {
        let shortened = name;

        const charsToCut = name.length - dtl.lengthMax;
        for (let i = 0; i < charsToCut; i++) {
          const charindex = rnd(6, shortened.length - dtl.ShortName.length - 3);
          shortened = shortened.substring(0, charindex) + '' + shortened.substring(charindex + 1);
        }
        if (re.test(shortened)) {
          setResourceName(shortened);
        } else {
          setResourceName('SHORTENED NAME DOES NOT MATCH REGEX');
        }
      } else {
        if (re.test(name)) {
          setResourceName(name);
        } else {
          setResourceName('NAME DOES NOT MATCH REGEX');
        }
      }
    };

    const resname = `az-${region}-${env}-${workload}-${detail.ShortName}01`;
    if (detail.scope === 'global') {
      setIsGlobal(true);
    } else {
      setIsGlobal(false);
    }
    if (
      detail.validText.toLowerCase().includes('lowercase') &&
      detail.validText.toLowerCase().includes('hyphens')
    ) {
      shorten(resname.toLowerCase(), detail);
    } else if (detail.validText.toLowerCase().includes('lowercase')) {
      shorten(resname.replace(/-/g, '').toLowerCase(), detail);
    } else if (detail.scope === 'global') {
      shorten(resname.toUpperCase(), detail);
    } else {
      setResourceName(resname.toUpperCase());
    }
  }, [region, env, workload, detail]);

  return (
    <Box component={Paper} sx={{ my: '20px', p: '20px', boxShadow: '0' }}>
      <Typography variant="h6">Resource Name</Typography>
      <Divider sx={{ mt: '10px', mb: '15px' }} />
      {noShortName ? (
        <div className="noshortname">
          <Box
            sx={{
              backgroundColor: '#7c4dff',
              borderRadius: 1,
              boxShadow: '0',
            }}
          >
            <Typography
              variant="body2"
              fontWeight="300"
              sx={{ py: '10px', px: '10px', display: 'flex', justifyContent: 'center' }}
            >
              This resource doesn't have a shortname specified. Probably you selected a subtype of a
              resource / a resource with no specific naming requirements.
            </Typography>
            <Typography variant="body2" fontWeight="300" sx={{ pt: '5px', pb: '10px', px: '10px' }}>
              The 'scope' property should provide more information
            </Typography>
          </Box>
        </div>
      ) : (
        <div className="shortname">
          {isGlobal && (
            <Box
              sx={{
                backgroundColor: '#ff1744',
                borderRadius: 1,
                boxShadow: '0',
              }}
            >
              <Typography
                variant="body2"
                fontWeight="300"
                sx={{ py: '10px', px: '10px', display: 'flex', justifyContent: 'center' }}
              >
                Scope is 'global', this means that the name may be shortened to fit the max length
                property. The resource name must also be globally unique in most cases, so take this
                as a proposal
              </Typography>
            </Box>
          )}
          <Box sx={{ my: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TextField
              id="result"
              value={resourceName}
              InputProps={{
                readOnly: true,
                style: { fontFamily: 'Fira Code Light' },
              }}
              sx={{ width: '90%', mr: '5px' }}
            ></TextField>
            <CopyToClipboardButton text={`${resourceName}`} />
          </Box>
        </div>
      )}
    </Box>
  );
};

export default NamingResult;
